import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CourseDetails from "./CourseDetails";
import Dropdown from "./Dropdown";
import { Button } from "react-bootstrap";
import { getTokenFromCookie } from "../Utils/cookieUtils";
import "./EducationDetail.css";

// EducationDetail.propTypes = {
//   currentData: PropTypes.array,
//   utMember: PropTypes.array, // Same as above, define the actual expected type
//   handleDataRefresh: PropTypes.func.isRequired,
// };

export default function EducationDetail({
  currentData,
  utMemeber,
  handleDataRefresh,
}) {
  const [utMemberOptions, setUtMemberOptions] = useState([]);
  const [assignUtMember, setAssignUtMember] = useState("");
  const [postData, setPostData] = useState({
    student_its: "",
    member_its: "",
  });

  useEffect(() => {
    // Extract member options from utMemeber data
    if (utMemeber && Array.isArray(utMemeber)) {
      const options = utMemeber.map((member) => ({
        value: member.its,
        label: member.name,
      }));
      setUtMemberOptions(options);
    }
  }, [utMemeber]);

  const handleUTMemberSelect = (value) => {
    setAssignUtMember(value);
    setPostData((prevData) => ({
      ...prevData,
      member_its: value,
    }));
  };

  const getToken = getTokenFromCookie();

  const handleSubmit = async (its_id) => {
    const postData = {
      student_its: its_id,
      member_its: assignUtMember,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API}migrating_students/coordinator/tagStudent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${getToken}`,
          },
          body: JSON.stringify(postData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data) {
        handleDataRefresh();
      }
    } catch (error) {
      console.error("There was a problem with the POST request:", error);
    }
  };

  const handleRemove = async (its_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API}migrating_students/coordinator/removeTag/${its_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Basic ${getToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data) {
        handleDataRefresh();
      }
    } catch (error) {
      console.error("There was a problem with the DELETE request:", error);
    }
  };

  return (
    <>
      {currentData &&
        currentData.map((item, idx) => (
          <div key={idx} className="user-card">
            <div className="user-card-content">
              <div
                className="d-flex"
                style={{ alignItems: "center", height: "100%", width: "42%" }}
              >
                <img
                  src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${item.its_id}.png`}
                  alt="user"
                  className="user-img"
                />
                <div className="user-details">
                  <p>{item.its_id}</p>
                  <p>{item.name}</p>
                  <p>
                    {item.gender.toLowerCase() === "m" ? "Male" : "Female"}{" "}
                    {item.age} years
                  </p>
                  <p>{item.email}</p>
                  <p>{item.mobile}</p>
                  <p>
                    <strong>Jamaat:</strong> {item.jamaat}
                  </p>
                  <p>
                    <strong>Current City:</strong> {item.current_edu_city}
                  </p>
                </div>
              </div>

              <div className="study-history">
                {item.future_edu_course && (
                  <CourseDetails
                    course={item.future_edu_course}
                    institute={item.future_edu_institute}
                    jawab={item.future_edu_jawab}
                    startDate={item.future_edu_course_start_date}
                    endDate={item.future_edu_course_end_date}
                    period={"future"}
                    title={"Future Education"}
                  />
                )}
                <CourseDetails
                  course={item.current_edu_course}
                  institute={item.current_edu_institute}
                  jawab={item.current_edu_jawab}
                  startDate={item.current_edu_course_start_date}
                  endDate={item.current_edu_course_end_date}
                  period={"current"}
                  title={"Current Education"}
                />
                <CourseDetails
                  course={item.last_edu_course}
                  institute={item.last_edu_institute}
                  jawab={item.last_edu_jawab}
                  startDate={item.last_edu_course_start_date}
                  endDate={item.last_edu_course_end_date}
                  period={"last"}
                  title={"Last Education"}
                />
                {item.is_migrated === "1" ? (
                  <>
                    <Dropdown
                      options={utMemberOptions}
                      onSelect={handleUTMemberSelect}
                      memberName={item.member_name}
                    />
                    {item.member_name ? (
                      <Button
                        onClick={() => handleRemove(item.its_id)}
                        variant="danger"
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button onClick={() => handleSubmit(item.its_id)}>
                        Assign
                      </Button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
