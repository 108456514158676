import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import "./AccordionFilter.css";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Sidebar from "./Sidebar";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <>
      <button
        type="button"
        className="toggle-element"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    </>
  );
}

function CustomToggleOne({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <>
      <button
        type="button"
        style={{
          backgroundColor: "#00336D",
          color: "#fff",
          borderRadius: "5px",
          textDecoration: "none",
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    </>
  );
}

function AccordionFilter(props) {
  const [filterText, setFilterText] = useState(
    "Araz done for currently studying"
  );
  const {
    sidebarData,
    handleRequest,
    EduStatus,
    streamData,
    ageLimit,
    handleAgeChnage,
    handleSubmitAge,
    currentJamaatName,
    allJamaatIds,
    handleJamaatChange,
  } = props;

  const darkColor = {
    background: "#00336D",
    color: "#fff",
  };

  const handleFiltertext = (text) => {
    setFilterText(text);
  };

  const whiteColor = {
    background: "#EDEDED",
    color: "#000",
  };

  return (
    <div className=" mt-1 d-block d-sm-none">
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <CustomToggleOne eventKey="1">
              <FontAwesomeIcon color={"#fff"} icon={faFilter} />
              Apply Filter
            </CustomToggleOne>
            <div className="font-weight-bold">{filterText}</div>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            {/* <CustomToggle eventKey="1"> */}
            <Card.Body>
              <div className="primary-bg">
                <div className="primary-label">Students in My Mauze</div>
                <div
                  className="mb-1 mt-2"
                  style={{ color: "white", fontSize: "0.85em" }}
                >
                  <strong>Age Group</strong>
                </div>
                <div className=" d-flex mb-1">
                  <input
                    type="number"
                    name="lowerAge"
                    value={ageLimit?.lowerAge}
                    className="form-control input-get"
                    aria-label="Text input with checkbox"
                    onChange={handleAgeChnage}
                  />
                  <input
                    type="number"
                    name="higherAge"
                    value={ageLimit?.higherAge}
                    className="form-control input-get"
                    aria-label="Text input with checkbox"
                    onChange={handleAgeChnage}
                  />
                  <button
                    type="button"
                    className="btn btn-secondary btn-get"
                    onClick={() =>
                      handleSubmitAge(ageLimit?.lowerAge, ageLimit?.higherAge)
                    }
                  >
                    Get data
                  </button>
                </div>

                <div className="dropdown mb-1">
                  <button
                    className="btn btn-primary btn-block dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <strong>{currentJamaatName}</strong>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {allJamaatIds?.map((item) => (
                      <a
                        className="dropdown-item"
                        key={item.jamaat_id}
                        onClick={() =>
                          handleJamaatChange(item.jamaat_id, item.jamaat)
                        }
                      >
                        {item.jamaat}
                      </a>
                    ))}
                  </div>
                </div>
                <Sidebar
                  sidebarData={sidebarData.Total_Student}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
                <Sidebar
                  sidebarData={sidebarData.Male_Student}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
                <Sidebar
                  sidebarData={sidebarData.Female_Student}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
                <Sidebar
                  sidebarData={sidebarData.Migrated_In}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                  theme={{
                    bg: "#ededed",
                    color: "#57774c",
                    activeBg: "#57774c",
                    activeColor: "#ededed",
                  }}
                  addClass="mt-3"
                />
              </div>
              <div className="sidebar-content">
                <strong>Raza Status:</strong>
              </div>
              {sidebarData &&
                sidebarData?.Raza_Status.map((item, idx) => (
                  <div
                    key={idx}
                    className="d-flex "
                    style={{
                      width: "100%",
                      ...(EduStatus === item.label ? darkColor : whiteColor),
                      borderRadius: "4px",
                      padding: "5px",
                      marginBottom: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleRequest(item.verb, item.label, item.download)
                    }
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "100%" }}
                    >
                      <div onClick={() => handleFiltertext(item.label)}>
                        {item.label}
                      </div>
                      <Badge
                        bg={EduStatus === item.label ? "light" : "secondary"}
                        text={EduStatus === item.label ? "dark" : "light"}
                      >
                        {item.count}
                      </Badge>
                    </div>
                  </div>
                ))}

              <div className="sidebar-content">
                <strong>Streams / Marhala:</strong>
              </div>
              {sidebarData &&
                sidebarData?.Stream.map((item, idx) => (
                  <div
                    key={idx}
                    className="d-flex "
                    style={{
                      width: "100%",
                      ...(EduStatus === item.label ? darkColor : whiteColor),
                      borderRadius: "4px",
                      padding: "5px",
                      marginBottom: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleRequest(item.verb, item.label, item.download)
                    }
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "100%" }}
                    >
                      <div onClick={() => handleFiltertext(item.label)}>
                        {item.label}
                      </div>
                      <Badge
                        bg={EduStatus === item.label ? "light" : "secondary"}
                        text={EduStatus === item.label ? "dark" : "light"}
                      >
                        {item.count}
                      </Badge>
                    </div>
                  </div>
                ))}

              <div className="sidebar-content">
                <strong>Other Categories:</strong>
              </div>
              {sidebarData &&
                sidebarData?.main_menu.map((item, idx) => (
                  <div
                    key={idx}
                    className="d-flex "
                    style={{
                      width: "100%",
                      ...(EduStatus === item.label ? darkColor : whiteColor),
                      borderRadius: "4px",
                      padding: "5px",
                      marginBottom: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleRequest(item.verb, item.label, item.download)
                    }
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "100%" }}
                    >
                      <div onClick={() => handleFiltertext(item.label)}>
                        {item.label}
                      </div>
                      <Badge
                        bg={EduStatus === item.label ? "light" : "secondary"}
                        text={EduStatus === item.label ? "dark" : "light"}
                      >
                        {item.count}
                      </Badge>
                    </div>
                  </div>
                ))}
            </Card.Body>
            {/* </CustomToggle> */}
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default AccordionFilter;
