import React from "react";
import logo from "../logotal.png";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useNavigate, useLocation } from "react-router-dom";
import { getCookie } from "../Utils/cookieUtils";
import "./MainNavbar.css";

export default function MainNavbar(props) {
  const { currentJamaatName, userFullName } = props;
  const navigate = useNavigate();
  let location = useLocation();

  const userName = getCookie("user_its");

  const handleUrl = (url) => {
    if (url === "dunyawi") {
      navigate("/");
    } else if (url === "deeni") {
      navigate("/deeni");
    } else if (url === "mauze") {
      navigate("/mauze-profile-entry");
    } else if (url === "task") {
      navigate("/task");
    } else if (url === "search") {
      navigate("/search");
    } else if (url === "imtihaan") {
      navigate("/imtihaan");
    } else if (url === "migrated-student") {
      navigate("/migrated-student");
    }
  };

  const onActive = {
    backgroundColor: "#fff",
    color: "#000",
  };

  return (
    <Navbar className="largeContainer" style={{ backgroundColor: "#002147" }}>
      <Container fluid>
        <div
          className="d-flex justify-content-between nav-container"
          style={{ maxWidth: "40%" }}
        >
          <a href="https://www.talabulilm.com">
            <img className="" src={logo} alt="img" />
          </a>
          {/* <h3>Current Education Status for Age: {ageLimit.lowerAge} - {ageLimit.higherAge}<br />{`${headerData ? headerData?.jamaat : ""}`} </h3> */}
          <h3>
            Education Dashboard
            {`${currentJamaatName ? " - " + currentJamaatName : ""}`}{" "}
          </h3>
        </div>
        <div className="d-flex justify-content-end nav-container">
          <div className="d-flex">
            <div className="image-header">
              {userFullName?.name}
              <br />
              <div>
                <div
                  className="top-nav-link "
                  style={{ ...(location.pathname === "/" ? onActive : "") }}
                  onClick={() => handleUrl("dunyawi")}
                >
                  Dunyawi
                </div>
                <a
                  className="top-nav-link "
                  style={{
                    ...(location.pathname === "/deeni" ? onActive : ""),
                  }}
                  onClick={() => handleUrl("deeni")}
                >
                  Deeni
                </a>
                <a
                  className="top-nav-link"
                  style={{
                    ...(location.pathname === "/mauze-profile-entry"
                      ? onActive
                      : ""),
                  }}
                  onClick={() => handleUrl("mauze")}
                >
                  Education Survey
                </a>
                {/* <a
                  className="top-nav-link"
                  style={{ ...(location.pathname === "/task" ? onActive : "") }}
                  onClick={() => handleUrl("task")}
                >
                  My Task
                </a> */}
                <a
                  className="top-nav-link"
                  style={{
                    ...(location.pathname === "/search" ? onActive : ""),
                  }}
                  onClick={() => handleUrl("search")}
                >
                  Search
                </a>
                <a
                  className="top-nav-link"
                  style={{
                    ...(location.pathname === "/imtihaan" ? onActive : ""),
                  }}
                  onClick={() => handleUrl("imtihaan")}
                >
                  Online Imtihaan
                </a>
                <a
                  className="top-nav-link"
                  style={{
                    ...(location.pathname === "/migrated-student"
                      ? onActive
                      : ""),
                  }}
                  onClick={() => handleUrl("migrated-student")}
                >
                  Guardianship
                </a>
              </div>
            </div>
            <img
              className="image-content"
              src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${userName}.png`}
              alt="img"
            />
          </div>
        </div>
      </Container>
    </Navbar>
  );
}
