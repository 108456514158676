import React from "react";
import Badge from "react-bootstrap/Badge";

export default function Sidebar(props) {
  const { sidebarData, handleRequest, EduStatus, streamData, addClass, theme } =
    props;

  const activeTheme = {
    background: theme?.activeBg ? theme.activeBg : "#00336D",
    color: theme?.activeColor ? theme.activeColor : "#fff",
  };

  const inActiveTheme = {
    background: theme?.bg ? theme.bg : "#EDEDED",
    color: theme?.color ? theme.color : "#000",
  };

  return (
    <>
      {sidebarData &&
        sidebarData.map((item, idx) => (
          <div
            key={idx}
            className={`d-flex lbl-sidebar ${addClass ? addClass : ""} `}
            style={{
              ...(EduStatus === item.label ? activeTheme : inActiveTheme),
            }}
            onClick={() => handleRequest(item.verb, item.label, item.download)}
          >
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div>{item.label}</div>
              <Badge
                bg={EduStatus === item.label ? "light" : "secondary"}
                text={EduStatus === item.label ? "dark" : "light"}
              >
                {item.count}
              </Badge>
            </div>
          </div>
        ))}
    </>
  );
}
