import React, { useState } from "react";

const Dropdown = ({ options, onSelect, memberName }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelect = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    onSelect(value); // Invoke the onSelect callback with the selected value
  };

  // Render the dropdown only if memberName doesn't exist
  if (memberName) {
    return (
      <div style={{ display: "inline-block", margin: "10px" }}>
        <label style={{ marginRight: "5px" }}>Assigned UT Member:</label>
        {memberName}
      </div>
    );
  }

  return (
    <div style={{ display: "inline-block", margin: "10px" }}>
      <label style={{ marginRight: "5px" }}>Assigned UT Member:</label>
      <select value={selectedValue} onChange={handleSelect}>
        <option value="">Select an option...</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
