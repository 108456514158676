import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./Routes/AppRoutes";
import DeeniRoutes from "./components/Deeni";
import SearchRoutes from "./components/Search";
import Migrated from "./components/Migrated";
import MuzeProfileForm from "./components/MuzeProfileForm";
import ImtihaanRoutes from "./components/Imtihaan";
import MigrateStudent from "./components/Migrated/MigrateStudent";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import MyTask from "./components/MyTask/MyTask";

import MainNavbar from "./components/MainNavbar";
import MobileNavbar from "./components/MobileNavbar";
import useFetch from "./useFetch";
import { useEffect, useState, useContext, createContext } from "react";
import MigratedInStudent from "./components/Migrated/MigratedInStudent";

import logo from "./logotal.png";

export const DataContext = createContext({
  currentSettings: "",
});
export default function App() {
  const [currentJamaatName, setCurrentJamaatName] = useState("");

  const changeCurrentJamaatName = (values) => {
    setCurrentJamaatName(values);
  };

  const [userFullName, setUserFullName] = useFetch();
  const [aamilsahebDetails, setAamilsahebDetails] = useFetch();
  const [allJamaatIds, setAllJamaatIds] = useFetch();
  const [currentJamaatId, setCurrentJamaatId] = useState();

  useEffect(() => {
    setUserFullName(`${process.env.REACT_APP_PROFILE_API}`);
    setAamilsahebDetails(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/details`
    );
    setAllJamaatIds(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/jamaatIDByitsID`
    );
  }, []);

  useEffect(() => {
    if (allJamaatIds && allJamaatIds.length > 0) {
      const inititalData = allJamaatIds?.filter((item) =>
        item.is_primary === 1 ? item.jamaat : ""
      );
      setCurrentJamaatId(inititalData[0]?.jamaat_id);
    }
  }, [allJamaatIds]);

  useEffect(() => {
    if (allJamaatIds && allJamaatIds.length > 0) {
      const jamaat = allJamaatIds?.filter((item) =>
        item.jamaat_id === currentJamaatId ? item.jamaat : ""
      );
      setCurrentJamaatName(jamaat[0].jamaat);
    }
  }, [currentJamaatId]);

  return (
    <DataContext.Provider
      value={{ currentJamaatName, changeCurrentJamaatName }}
    >
      {aamilsahebDetails !== null && aamilsahebDetails.length === 0 ? (
        <div className="unauthorisedScreen">
          <div className="unauthorisedMessage">
            <img src={logo} />
            <div className="unauthorisedMessageLight">
              <div>
                <h1>You are not authorized to view this page</h1>
                <h2>
                  You cannot view this page because it is only accessible with
                  the ITS Login of AmilSaheb. If you are logged in with a
                  different ITS, please log out and log in again using the ITS
                  Login of AmilSaheb.
                </h2>
              </div>
              <a href="https://talabulilm.com">Go back to talabulilm.com</a>
            </div>
          </div>
        </div>
      ) : (
        <BrowserRouter>
          <MainNavbar
            currentJamaatName={currentJamaatName}
            userFullName={userFullName}
            ageLimit={10}
          />
          <MobileNavbar />
          <Routes>
            <Route path="/" element={<AppRoutes />} />
            <Route path="/deeni" element={<DeeniRoutes />} />
            {/* <Routes/> */}
            <Route path="/mauze-profile-entry" element={<MuzeProfileForm />} />
            <Route path="/migrated" element={<Migrated />} />
            <Route path="/task" element={<MyTask />} />
            <Route path="/search" element={<SearchRoutes />} />
            <Route path="/imtihaan" element={<ImtihaanRoutes />} />
            <Route path="/migrated-student" element={<MigrateStudent />} />
          </Routes>
        </BrowserRouter>
      )}
    </DataContext.Provider>
  );
}
