import { useEffect, useState } from "react";
import { getTokenFromCookie } from "./Utils/cookieUtils";

export default function useFetch(url) {
  const [data, setData] = useState(null);

  const getToken = getTokenFromCookie();

  useEffect(() => {
    apiFn(url);
  }, [getToken]);

  const apiFn = (url) => {
    if (url === "") return;
    if (url === undefined) return;
    if (url === null) return;

    setData(null);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${getToken}`,
      },
    })
      .then((response) => response?.json())
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setData([]);
        // console.log(error);
      });
  };

  return [data, apiFn];
}
