import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import MainNavbar from "../MainNavbar";
import MobileNavbar from "../MobileNavbar";
import MigratedPage from "./MigratedPage";
import useFetch from "../../useFetch";
import { getTokenFromCookie } from "../../Utils/cookieUtils.js";

export default function AppRoutes() {
  const [sidebarData, setSideBarData] = useFetch();
  const [allJamaatIds, setAllJamaatIds] = useFetch();
  const [apiData, setApiData] = useFetch();
  const [userFullName, setUserFullName] = useFetch();
  const [currentJamaatName, setCurrentJamaatName] = useState();
  const [EduStatus, setEduStatus] = useState(
    "Araz done for currently studying"
  );
  const [myTask, setMyTask] = useFetch();
  const [ageLimit, setAgeLimit] = useState({ lowerAge: 3, higherAge: 27 });
  const [currentJamaatId, setCurrentJamaatId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const defaultSort = { field: "", direction: "" };
  const [sort, setSort] = useState(defaultSort);
  const [token, setToken] = useState(false);
  const [downloadRecord, setDownloadRecord] = useState(
    `?cmd=razastatus&jamaat=${currentJamaatId}&raza_status=Araz%20done`
  );

  const handleRequest = (verb, lable, downlaod) => {
    setApiData("");
    setSort(defaultSort);
    setApiData(`${process.env.REACT_APP_PROFILE_API}aamilsaheb/${verb}`);
    setEduStatus(lable);
    setDownloadRecord(downlaod);
    setCurrentData(apiData);
  };

  const handleSubmitAge = (lowerAgeLimit, higherAgeLimit) => {
    setAgeLimit({ lowerAge: lowerAgeLimit, higherAge: higherAgeLimit });
  };

  useEffect(() => {
    setToken(getTokenFromCookie());
  }, []);

  useEffect(() => {
    if (!ageLimit) return;
    if (!currentJamaatId) return;
    setSideBarData(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/filters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}`
    );
  }, [ageLimit]);

  useEffect(() => {
    if (sidebarData) {
      setSort(defaultSort);
      handleRequest(
        `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}/Araz%20done`,
        "Araz done for currently studying",
        `?cmd=razastatus&jamaat=${currentJamaatId}&raza_status=Araz%20done`
      );
    }
  }, [sidebarData]);

  useEffect(() => {
    const inititalData = allJamaatIds?.filter((item) => item.is_primary === 1);
    setMyTask(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/taskList/${inititalData[0]?.jamaat_id}`
    );
    setCurrentJamaatId(inititalData[0]?.jamaat_id);
  }, [allJamaatIds]);

  useEffect(() => {
    setUserFullName(process.env.REACT_APP_PROFILE_API);
    setAllJamaatIds(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/jamaatIDByitsID`
    );
  }, [token]);

  useEffect(() => {
    setCurrentData(apiData);
  }, [apiData]);

  const handleSort = (field) => {
    let direction = sort.direction;
    if (field === sort.field) {
      direction = sort.direction === "asc" ? "desc" : "asc";
    } else {
      direction = "asc";
    }
    const sortedData = currentData?.sort((a, b) => {
      if (field === "age")
        return direction === "asc" ? a[field] - b[field] : b[field] - a[field];
      return direction === "asc"
        ? a[field].localeCompare(b[field])
        : b[field].localeCompare(a[field]);
    });
    setCurrentData(sortedData);
    setSort({ field: field, direction: direction });
  };

  const handleJamaatChange = (jamaatId, jamaat) => {
    setCurrentJamaatId(jamaatId);
  };

  useEffect(() => {
    if (!currentJamaatId) return;
    setSideBarData(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/filters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}`
    );
    handleRequest(
      `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}/Araz%20done`,
      "Araz done for currently studying",
      `?cmd=razastatus&jamaat=${currentJamaatId}&raza_status=Araz%20done`
    );
    setMyTask(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/taskList/${currentJamaatId}`
    );
  }, [currentJamaatId]);

  return (
    <>
      <MainNavbar
        currentJamaatName={currentJamaatName}
        userFullName={userFullName}
      />
      <MobileNavbar />
      <MigratedPage
        sidebarData={sidebarData}
        currentJamaatName={currentJamaatName}
        currentData={currentData}
        sort={sort}
        handleJamaatChange={handleJamaatChange}
        allJamaatIds={allJamaatIds}
        EduStatus={EduStatus}
        downloadRecord={downloadRecord}
        handleRequest={handleRequest}
        handleSort={handleSort}
        jamaatId={currentJamaatId}
        handleSubmitAge={handleSubmitAge}
      />
      <ToastContainer />
    </>
  );
}
