import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, Row, Card } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Table from "./Table";
import "react-toastify/dist/ReactToastify.css";
import HashLoader from "react-spinners/HashLoader";
import { getTokenFromCookie } from "../Utils/cookieUtils"

// Chart.register(...registerables);
ChartJS.register(ArcElement, Tooltip, Legend);
export default function MuzeProfileForm() {
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [uniqueUserCount, setUniqueUserCount] = useState(0);
  const [totalCompletedCount, setTotalCompletedCount] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [usersPaperList, setUsersPaperList] = useState([]);
  const [languageWiseCountList, setLanguageWiseCountList] = useState([]);
  const [token, setToken] = useState();
  const [color, setColor] = useState("#00336D");
  const [isGroupListIsLoading, setGroupListIsLoading] = useState(false);
  const [isUserListIsLoading, setUserListIsLoading] = useState(false);
  const [isUserPaperListIsLoading, setUserPaperListIsLoading] = useState(false);

  const userColumns = [
    {
      Header: "User Name",
      accessor: "name",
      sortable: true,
    },
    {
      Header: "Jamaat",
      accessor: "jamaat",
      sortable: true,
    },
    {
      Header: "Phone",
      sortable: true,
      accessor: "phone",
      Cell: ({ row }) => {
        return (
          <p>
            <a href={`tel:${row.original.phone}`}>{row.original.phone}</a>{" "}
          </p>
        );
      },
    },
    {
      Header: "Gender",
      sortable: true,
      accessor: "gender",
      Cell: ({ row }) => {
        return <p>{row.original.gender == "M" ? "Male" : "Female"}</p>;
      },
    },
    {
      Header: "Age",
      accessor: "age",
      sortable: true,
    },
    {
      Header: "Score",
      accessor: "score",
      sortable: true,
    },
    {
      Header: "Paper Attempted",
      accessor: "attempted_papers",
      sortable: true,
    },
    // {
    //   name: "Actions",
    //   cell: () => {
    //     return <Button variant="success">View</Button>;
    //   },
    // },
  ];

  const columns = [
    {
      Header: "Quizz Name",
      accessor: "Name",
      sortable: true,
    },
    {
      Header: "Total Users",
      accessor: "totalusers",
      sortable: true,
    },
    {
      Header: "Passed Users",
      accessor: "totalPassedUser",
      sortable: true,
    },
    {
      Header: "Failed Users",
      accessor: "totalFailedUser",
      sortable: true,
    },
    {
      Header: "100% Score",
      sortable: true,
      Cell: ({ row }) => {
        return (
          <p>
            {(
              row.original.total100PercScoreCount / row.original.totalusers
            ).toFixed(2)}
          </p>
        );
      },
      accessor: (row) =>
        (row.total100PercScoreCount / row.totalusers).toFixed(2),
    },
    {
      Header: "Avg Time",
      accessor: "avgTime",
      sortable: true,
    },
  ];

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const apiUrlImtihaan = process.env.REACT_APP_IMTIHAAN_API
    setGroupListIsLoading(true);
    const token = getTokenFromCookie();
    setToken(token);
    fetch(`${apiUrlImtihaan}groupList`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setGroupListIsLoading(false);
        const data = responseJson.map((item) => {
          return { label: item.name, value: item.id };
        });
        data && setGroupList(data);
        data && setSelectedGroup(data[data.length - 1]);
      })
      .catch((error) => {
        setGroupListIsLoading(false);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const apiUrlImtihaan = process.env.REACT_APP_IMTIHAAN_API
    if (selectedGroup) {
      fetch(
        `${apiUrlImtihaan}totalUniqueUserCountList/${selectedGroup?.value}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            setUniqueUserCount(responseJson[0]?.total);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      fetch(
        `${apiUrlImtihaan}totalCompletedCount/${selectedGroup?.value}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            setTotalCompletedCount(responseJson[0]["COUNT(q.UserID)"]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setUserListIsLoading(true);
      fetch(
        `${apiUrlImtihaan}totalUniqueUserList/${selectedGroup?.value}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            setUsersList(responseJson);
          }
          setUserListIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setUserListIsLoading(false);
        });
      setUserPaperListIsLoading(true);
      fetch(
        `${apiUrlImtihaan}totalUniqueUsersPaperWise/${selectedGroup?.value}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            setUsersPaperList(responseJson);
          }
          setUserPaperListIsLoading(false);
        })
        .catch((error) => {
          setUserPaperListIsLoading(false);
          console.error(error);
        });
      fetch(
        `${apiUrlImtihaan}totalUniqueUserLanguageWiseCount/${selectedGroup?.value}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            setLanguageWiseCountList(responseJson);
            const dataObj = {};
            const lable = responseJson?.map((item) => item.language);
            const dataset = {
              backgroundColor: "#b91617",
            };

            dataset.lable = responseJson?.map((item) => item.language);
            dataset.data = responseJson?.map((item) => item.total);
            dataObj.labels = lable;
            dataObj.datasets = [dataset];
            setChartData(dataObj);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedGroup]);

  const handleGroupSelect = (data) => {
    setUniqueUserCount(0);
    setTotalCompletedCount(0);
    setSelectedGroup(data);
    setUsersPaperList([]);
    setUsersList([]);
  };

  return (
    <>
      <div className="form-container">
        <div className="container mt-3 row form-cotent">
          <div style={{ marginLeft: "auto", width: "50%" }}>
            <Select
              options={groupList}
              value={selectedGroup}
              onChange={(data) => handleGroupSelect(data)}
            />
          </div>
          <div className="form-header">
            <Row>
              <Col sm={12}>
                <div className="past-entries">
                  {isGroupListIsLoading ? (
                    <div className="loader-content">
                      <HashLoader color={color} size={78} />
                    </div>
                  ) : (
                    <>
                      <p>
                        Total Unique Users :{" "}
                        <b>{uniqueUserCount ? uniqueUserCount : 0}</b>
                      </p>
                      <hr style={{ width: "300px" }} />
                      <p>
                        Total Completed Users :{" "}
                        <b>{totalCompletedCount ? totalCompletedCount : 0}</b>
                      </p>
                    </>
                  )}
                  <hr style={{ width: "300px" }} />
                  {languageWiseCountList?.map((item) => {
                    return (
                      <>
                        <p>
                          Total {item.language} Users{" "}
                          <b>{item.total ? item.total : 0}</b>
                        </p>
                      </>
                    );
                  })}

                  <hr style={{ width: "300px" }} />
                  <div>
                    <Doughnut
                      height={250}
                      width={250}
                      data={chartData}
                      options={{
                        // responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            display: true,
                            color: "white",
                            anchor: "center",
                            clamp: true,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="form-header">
            <Row>
              <Col sm={12}>
                <Card>
                  <Card.Header>TOTAL USERS PAPER WISE</Card.Header>
                  <Card.Body>
                    <div style={{ overflowX: "auto" }}>
                      <Table
                        users={usersPaperList}
                        columnsData={columns}
                        isLoading={isUserPaperListIsLoading}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="form-header">
            <Row>
              <Col sm={12}>
                <Card>
                  <Card.Header>USERS LIST</Card.Header>
                  <Card.Body>
                    <div style={{ overflowX: "auto" }}>
                      <Table
                        users={usersList}
                        columnsData={userColumns}
                        isLoading={isUserListIsLoading}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
