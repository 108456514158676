import React, { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import DeeniPage from "./DeeniPage";
import useFetch from "../../useFetch";
import { DataContext } from "../../App";
import { getTokenFromCookie } from "../../Utils/cookieUtils.js"

export default function AppRoutes() {
  const { currentJamaatName, changeCurrentJamaatName } = useContext(DataContext);
  const [sidebarData, setSideBarData] = useFetch();
  const [allJamaatIds, setAllJamaatIds] = useFetch();
  const [apiData, setApiData] = useFetch();
  const [EduStatus, setEduStatus] = useState(
    "Araz done for currently studying"
  );
  const [myTask, setMyTask] = useFetch();
  const [ageLimit, setAgeLimit] = useState({ lowerAge: 3, higherAge: 27 });
  const [currentJamaatId, setCurrentJamaatId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const defaultSort = { field: "", direction: "" };
  const [sort, setSort] = useState(defaultSort);
  const [token, setToken] = useState(false);
  const [downloadRecord, setDownloadRecord] = useState(
    `?cmd=razastatus&deeni=1&jamaat=${currentJamaatId}&raza_status=Araz%20done`
  );

  const handleRequest = (verb, lable, downlaod) => {
    setApiData("");
    setSort(defaultSort);
    setApiData(`${process.env.REACT_APP_PROFILE_API}aamilsaheb/deeni/${verb}`);
    setEduStatus(lable);
    setDownloadRecord(downlaod);
    setCurrentData(apiData);
  };

  const handleSubmitAge = (lowerAgeLimit, higherAgeLimit) => {
    setAgeLimit({ lowerAge: lowerAgeLimit, higherAge: higherAgeLimit });
  };

  useEffect(() => {
    setToken( getTokenFromCookie() )
  }, [])

  useEffect(() => {
    if (!ageLimit) return;
    if (!currentJamaatId) return;
    setSideBarData(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/deeni/filters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}`
    );
  }, [ageLimit]);

  useEffect(() => {
    if (sidebarData) {
      setSort(defaultSort);
      handleRequest(
        `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}/Araz%20done`,
        "Araz done for currently studying",
        `?cmd=razastatus&deeni=1&jamaat=${currentJamaatId}&raza_status=Araz%20done`
      );
    }
  }, [sidebarData]);

  useEffect(() => {
    if (allJamaatIds && allJamaatIds.length > 0) {
      const inititalData = allJamaatIds?.filter(
        (item) => item.is_primary === 1
      );
      setMyTask(
        `${process.env.REACT_APP_PROFILE_API}aamilsaheb/deeni/taskList/${inititalData[0]?.jamaat_id}`
      );
      setCurrentJamaatId(inititalData[0]?.jamaat_id);
    }
  }, [allJamaatIds]);

  useEffect(() => {
    setAllJamaatIds(`${process.env.REACT_APP_PROFILE_API}aamilsaheb/deeni/jamaatIDByitsID`);
  }, [token]);

  useEffect(() => {
    setCurrentData(apiData);
  }, [apiData]);

  const handleSort = (field) => {
    let direction = sort.direction;
    if (field === sort.field) {
      direction = sort.direction === "asc" ? "desc" : "asc";
    } else {
      direction = "asc";
    }
    const sortedData = currentData?.sort((a, b) => {
      if (field === "age")
        return direction === "asc" ? a[field] - b[field] : b[field] - a[field];
      return direction === "asc"
        ? a[field].localeCompare(b[field])
        : b[field].localeCompare(a[field]);
    });
    setCurrentData(sortedData);
    setSort({ field: field, direction: direction });
  };

  const handleJamaatChange = (jamaatId, jamaat) => {
    setCurrentJamaatId(jamaatId);
  };

  useEffect(() => {
    if (!currentJamaatId) return;
    setSideBarData(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/deeni/filters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}`
    );
    const jamaat = allJamaatIds?.filter((item) =>
      item.jamaat_id === currentJamaatId ? item.jamaat : ""
    );
    changeCurrentJamaatName(jamaat[0].jamaat);
    handleRequest(
      `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}/Araz%20done`,
      "Araz done for currently studying",
      `?cmd=razastatus&deeni=1&jamaat=${currentJamaatId}&raza_status=Araz%20done`
    );
    setMyTask(`${process.env.REACT_APP_PROFILE_API}aamilsaheb/deeni/taskList/${currentJamaatId}`);
  }, [currentJamaatId]);

  return (
    <>
      <DeeniPage
        sidebarData={sidebarData}
        currentJamaatName={currentJamaatName}
        currentData={currentData}
        sort={sort}
        handleJamaatChange={handleJamaatChange}
        allJamaatIds={allJamaatIds}
        EduStatus={EduStatus}
        downloadRecord={downloadRecord}
        handleRequest={handleRequest}
        handleSort={handleSort}
        jamaatId={currentJamaatId}
        handleSubmitAge={handleSubmitAge}
      />
      <ToastContainer />
    </>
  );
}