import React, { useEffect, useState } from "react";
import "./MuzeForm.css";
import useFetch from "../useFetch";
import Select from "react-select";
import EducationDetail from "./EducationDetail";

import "./datePicker.css";
import { ToastContainer, toast } from "react-toastify";
import Modals from "./Modals";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";

import "react-date-picker/dist/DatePicker.css";

import { useMutation } from "react-query";

import "react-toastify/dist/ReactToastify.css";
import { getTokenFromCookie } from "../Utils/cookieUtils";

export default function MuzeProfileForm() {
  const [murhala, setMurhala] = useFetch();
  const [selectedMarhala, setSelectedMarhala] = useState({});
  const [getCourse, setGetCourse] = useFetch("");
  const [getCountry, setGetCountry] = useFetch("");
  const [getCity, setGetCity] = useFetch("");
  const [getAccommodation, setGetAccommodation] = useFetch("");
  const [getCurrency, setGetCurrency] = useFetch("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [getInstitute, setGetInstitute] = useFetch("");
  const [amount, setAmount] = useState();
  const [itsValue, setItsValue] = useState("");
  const [getIts, setIts] = useState("");
  const [itsData, SetItsData] = useState();
  const [fmb, setFmb] = useState("");
  const [subMessage, setSubMessage] = useState(null);
  const [subError, setSubError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const araizApiUrl = process.env.REACT_APP_ARAIZ_API;

  const [loading, setLoading] = useState(false);

  const [remaingIts, setRemaingIts] = useState("");
  const [entryFormData, setEntryFormData] = useState({
    its_id: "",
    marhala: "",
    course: "",
    country: "",
    city: "",
    residence_adderess_1: "",
    residence_adderess_2: "",
    residence_pincode: "",
    fmb: "",
    institute: "",
    accommodation: "",
    course_start_date: new Date(),
    course_end_date: new Date(),
    annual_fees: "",
    currency: "",
    scholarship: "",
  });
  const [errorsMessage, setErrorMessage] = useState({
    its_id: "",
    marhala: "",
    course: "",
    country: "",
    city: "",
    residence_adderess_1: "",
    residence_adderess_2: "",
    residence_pincode: "",
    fmb: "",
    institute: "",
    accommodation: "",
    course_start_date: "",
    course_end_date: "End Date should be more than 1 month from Start Date.",
    annual_fees: "",
    currency: "",
    scholarship: "",
  });

  const [modalValue, setModalValue] = useState({
    country: "",
    city: "",
    institute: "",
  });

  const getToken = getTokenFromCookie();

  useEffect(() => {
    if (subMessage !== null) {
      const timeoutId = setTimeout(() => {
        setSubMessage(null);
        setSubError(null);
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [subMessage]);

  useEffect(() => {
    setMurhala(`${process.env.REACT_APP_ARAIZ_API}user/marhalaDetails`);
    setGetCountry(`${process.env.REACT_APP_ARAIZ_API}user/countryDetails`);
    setGetAccommodation(
      `${process.env.REACT_APP_ARAIZ_API}user/accomodationDetails`
    );
    setGetCurrency(`${process.env.REACT_APP_API_URL}getCurrenciesList`);
  }, []);

  useEffect(() => {
    selectedMarhala?.value &&
      setGetCourse(
        `${process.env.REACT_APP_ARAIZ_API}user/courseDetails/${Number(
          selectedMarhala.value
        )}`
      );
  }, [selectedMarhala]);

  useEffect(() => {
    if (selectedCountry) {
      setGetCity(
        `${process.env.REACT_APP_ARAIZ_API}user/cityDetailsByCountryISO/${selectedCountry.value}`
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCity) {
      setGetInstitute(
        `${process.env.REACT_APP_ARAIZ_API}user/instituteDetailsBycityID/${selectedCity.value}`
      );
    }
  }, [selectedCity]);

  useEffect(() => {
    let postItsIds = getIts.replace(/\n/gi, ",");
    fetch(`${apiUrl}currentEducationDetails`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${getToken}`,
      },
      body: JSON.stringify({
        its_id: postItsIds,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        SetItsData(responseJson);
        let result = Object.values(responseJson?.remaining_its).join("\n");
        setRemaingIts(result);
        let Itsresult = Object.values(responseJson?.remaining_its).join(",");
        setEntryFormData({ ...entryFormData, its_id: Itsresult });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [getIts]);

  const checkValidation = () => {
    let validated = true;
    const validationErrors = Object.keys(entryFormData).map((e) => {
      if (!entryFormData[e]) {
        validated = false;
        return { [e]: "Please fill this field" };
      } else if (e === "course_start_date" || e === "course_end_date") {
        validated = false;
        return { [e]: "" };
      }
    });
    if (!validated) {
      setErrorMessage(validationErrors);
    }
    return validated;
  };

  const handleSubmit = async () => {
    try {
      // const isValidated = checkValidation();

      // if (!isValidated) {
      //   setSubMessage.toast("Validation failed. Please fill up all the required fields.");
      //   setSubError.toast('error');
      //   return;
      // }

      const response = await fetch(`${apiUrl}postEducationDetails`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${getToken}`,
        },
        body: JSON.stringify({
          ...entryFormData,
        }),
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        toast.success("Records added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(responseJson.error.toUpperCase(), {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Some error occurred while saving the data");
    }
  };

  const handleSubmitModal = (url, getData) => {
    const apiRUL = process.env.REACT_APP_ARAIZ_API;
    fetch(`${araizApiUrl}${url}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${getToken}`,
      },
      body: JSON.stringify({
        ...getData,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        toast.success("Records added successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Some Error occured while saving the data");
      });
  };

  const handleChange = (selectedOptions, item) => {
    if (item === "marhala") {
      setSelectedMarhala(selectedOptions);
    } else if (item === "country") {
      setSelectedCountry(selectedOptions);
    } else if (item === "city") {
      setSelectedCity(selectedOptions);
    } else if (item === "fmb") {
      setFmb(selectedOptions);
    }
    setEntryFormData({ ...entryFormData, [item]: selectedOptions.label });
  };

  const handleChangeData = (date, dateLabel) => {
    setEntryFormData((prevData) => ({ ...prevData, [dateLabel]: date }));

    if (
      dateLabel === "course_end_date" &&
      date <= entryFormData.course_start_date
    ) {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        course_end_date:
          "End Date should be more than 1 month from Start Date.",
      }));
    } else {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        course_end_date: "",
      }));
      setEntryFormData({ ...entryFormData, [dateLabel]: date });
    }
  };

  //  ----------------------

  const handleChangeModal = (e) => {
    setModalValue({ ...modalValue, [e.target.name]: e.target.value });
  };

  const handleFormData = (field, value) => {
    setEntryFormData({ ...entryFormData, [field]: value });
  };

  const handleSubmitIts = async () => {
    try {
      setLoading(true);
      setIts(itsValue);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      // Handle the error if needed
      setLoading(false);
    }
  };

  const mapItems = (items, valueKey = "id", labelKey = "text") =>
    items?.map((item) => ({
      value: item[valueKey],
      label: item[labelKey],
    })) || [];

  const newMurhala = mapItems(murhala);
  const newGetCourse = mapItems(getCourse, "id", "course_name");
  const newGetCountry = mapItems(getCountry, "iso2", "name");
  const newGetCity = mapItems(getCity, "id", "city_name");
  const newGetAccommodation = mapItems(getAccommodation, "id", "name");
  const newGetCurrency = mapItems(getCurrency, "id", "currency_name");
  const newGetInstitute = mapItems(getInstitute, "id", "name");
  const newGetFmb = mapItems(
    [
      { id: "1", name: "Yes" },
      { id: "0", name: "No" },
    ],
    "id",
    "name"
  );

  // const Currency = [
  //   { value: 'ADA', label: 'ADA -Cardano' },
  //   { value: 'AED', label: 'AED -Emirati Dirham' },
  //   { value: 'AFN', label: 'AFN -Afghan Afghani' },
  //   { value: 'ALL', label: 'ALL -Albanian Lek' },
  //   { value: 'AMD', label: 'AMD -Armenian Dram' },
  //   { value: 'ANG', label: 'ANG -Dutch Guilder' },
  //   { value: 'AOA', label: 'AOA -Angolan Kwanza' },
  //   { value: 'ARS', label: 'ARS -Argentine Peso' },
  //   { value: 'AUD', label: 'AUD -Australian Dollar' },
  //   { value: 'AWG', label: 'AWG -Aruban or Dutch Guilder' },
  //   { value: 'AZN', label: 'AZN -Azerbaijan Manat' },
  //   { value: 'BAM', label: 'BAM -Bosnian Convertible Mark' },
  //   { value: 'BBD', label: 'BBD -Barbadian or Bajan Dollar' },
  //   { value: 'BCH', label: 'BCH -Bitcoin Cash' },
  //   { value: 'BDT', label: 'BDT -Bangladeshi Taka' },
  //   { value: 'BGN', label: 'BGN -Bulgarian Lev' },
  //   { value: 'BHD', label: 'BHD -Bahraini Dinar' },
  //   { value: 'BIF', label: 'BIF -Burundian Franc' },
  //   { value: 'BMD', label: 'BMD -Bermudian Dollar' },
  //   { value: 'BND', label: 'BND -Bruneian Dollar' },
  //   { value: 'BOB', label: 'BOB -Bolivian Bolíviano' },
  //   { value: 'BRL', label: 'BRL -Brazilian Real' },
  //   { value: 'BSD', label: 'BSD -Bahamian Dollar' },
  //   { value: 'BTC', label: 'BTC -Bitcoin' },
  //   { value: 'BTN', label: 'BTN -Bhutanese Ngultrum' },
  //   { value: 'BWP', label: 'BWP -Botswana Pula' },
  //   { value: 'BYN', label: 'BYN -Belarusian Ruble' },
  //   { value: 'BYR', label: 'BYR -Belarusian Ruble' },
  //   { value: 'BZD', label: 'BZD -Belizean Dollar' },
  //   { value: 'CAD', label: 'CAD -Canadian Dollar' },
  //   { value: 'CDF', label: 'CDF -Congolese Franc' },
  //   { value: 'CHF', label: 'CHF -Swiss Franc' },
  //   { value: 'CLP', label: 'CLP -Chilean Peso' },
  //   { value: 'CNY', label: 'CNY -Chinese Yuan Renminbi' },
  //   { value: 'COP', label: 'COP -Colombian Peso' },
  //   { value: 'CRC', label: 'CRC -Costa Rican Colon' },
  //   { value: 'CUC', label: 'CUC -Cuban Convertible Peso' },
  //   { value: 'CUP', label: 'CUP -Cuban Peso' },
  //   { value: 'CVE', label: 'CVE -Cape Verdean Escudo' },
  //   { value: 'CZK', label: 'CZK -Czech Koruna' },
  //   { value: 'DJF', label: 'DJF -Djiboutian Franc' },
  //   { value: 'DKK', label: 'DKK -Danish Krone' },
  //   { value: 'DOP', label: 'DOP -Dominican Peso' },
  //   { value: 'DOT', label: 'DOT -Polkadot' },
  //   { value: 'DZD', label: 'DZD -Algerian Dinar' },
  //   { value: 'EEK', label: 'EEK -Estonian Kroon' },
  //   { value: 'EGP', label: 'EGP -Egyptian Pound' },
  //   { value: 'ERN', label: 'ERN -Eritrean Nakfa' },
  //   { value: 'ETB', label: 'ETB -Ethiopian Birr' },
  //   { value: 'ETH', label: 'ETH -Ethereum' },
  //   { value: 'EUR', label: 'EUR -Euro' },
  //   { value: 'FJD', label: 'FJD -Fijian Dollar' },
  //   { value: 'FKP', label: 'FKP -Falkland Island Pound' },
  //   { value: 'GBP', label: 'GBP -British Pound' },
  //   { value: 'GEL', label: 'GEL -Georgian Lari' },
  //   { value: 'GGP', label: 'GGP -Guernsey Pound' },
  //   { value: 'GHS', label: 'GHS -Ghanaian Cedi' },
  //   { value: 'GIP', label: 'GIP -Gibraltar Pound' },
  //   { value: 'GMD', label: 'GMD -Gambian Dalasi' },
  //   { value: 'GNF', label: 'GNF -Guinean Franc' },
  //   { value: 'GTQ', label: 'GTQ -Guatemalan Quetzal' },
  //   { value: 'GYD', label: 'GYD -Guyanese Dollar' },
  //   { value: 'HKD', label: 'HKD -Hong Kong Dollar' },
  //   { value: 'HNL', label: 'HNL -Honduran Lempira' },
  //   { value: 'HRK', label: 'HRK -Croatian Kuna' },
  //   { value: 'HTG', label: 'HTG -Haitian Gourde' },
  //   { value: 'HUF', label: 'HUF -Hungarian Forint' },
  //   { value: 'IDR', label: 'IDR -Indonesian Rupiah' },
  //   { value: 'ILS', label: 'ILS -Israeli Shekel' },
  //   { value: 'IMP', label: 'IMP -Isle of Man Pound' },
  //   { value: 'INR', label: 'INR -Indian Rupee' },
  //   { value: 'IQD', label: 'IQD -Iraqi Dinar' },
  //   { value: 'IRR', label: 'IRR -Iranian Rial' },
  //   { value: 'ISK', label: 'ISK -Icelandic Krona' },
  //   { value: 'JEP', label: 'JEP -Jersey Pound' },
  //   { value: 'JMD', label: 'JMD -Jamaican Dollar' },
  //   { value: 'JOD', label: 'JOD -Jordanian Dinar' },
  //   { value: 'JPY', label: 'JPY -Japanese Yen' },
  //   { value: 'KES', label: 'KES -Kenyan Shilling' },
  //   { value: 'KGS', label: 'KGS -Kyrgyzstani Som' },
  //   { value: 'KHR', label: 'KHR -Cambodian Riel' },
  //   { value: 'KMF', label: 'KMF -Comorian Franc' },
  //   { value: 'KPW', label: 'KPW -North Korean Won' },
  //   { value: 'KRW', label: 'KRW -South Korean Won' },
  //   { value: 'KWD', label: 'KWD -Kuwaiti Dinar' },
  //   { value: 'KYD', label: 'KYD -Caymanian Dollar' },
  //   { value: 'KZT', label: 'KZT -Kazakhstani Tenge' },
  //   { value: 'LAK', label: 'LAK -Lao Kip' },
  //   { value: 'LBP', label: 'LBP -Lebanese Pound' },
  //   { value: 'LKR', label: 'LKR -Sri Lankan Rupee' },
  //   { value: 'LRD', label: 'LRD -Liberian Dollar' },
  //   { value: 'LSL', label: 'LSL -Basotho Loti' },
  //   { value: 'LTC', label: 'LTC -Litecoin' },
  //   { value: 'LTL', label: 'LTL -Lithuanian Litas' },
  //   { value: 'LVL', label: 'LVL -Latvian Lat' },
  //   { value: 'LYD', label: 'LYD -Libyan Dinar' },
  //   { value: 'MAD', label: 'MAD -Moroccan Dirham' },
  //   { value: 'MDL', label: 'MDL -Moldovan Leu' },
  //   { value: 'MGA', label: 'MGA -Malagasy Ariary' },
  //   { value: 'MKD', label: 'MKD -Macedonian Denar' },
  //   { value: 'MMK', label: 'MMK -Burmese Kyat' },
  //   { value: 'MNT', label: 'MNT -Mongolian Tughrik' },
  //   { value: 'MOP', label: 'MOP -Macau Pataca' },
  //   { value: 'MRU', label: 'MRU -Mauritanian Ouguiya' },
  //   { value: 'MUR', label: 'MUR -Mauritian Rupee' },
  //   { value: 'MVR', label: 'MVR -Maldivian Rufiyaa' },
  //   { value: 'MWK', label: 'MWK -Malawian Kwacha' },
  //   { value: 'MXN', label: 'MXN -Mexican Peso' },
  //   { value: 'MYR', label: 'MYR -Malaysian Ringgit' },
  //   { value: 'MZN', label: 'MZN -Mozambican Metical' },
  //   { value: 'NAD', label: 'NAD -Namibian Dollar' },
  //   { value: 'NGN', label: 'NGN -Nigerian Naira' },
  //   { value: 'NIO', label: 'NIO -Nicaraguan Cordoba' },
  //   { value: 'NOK', label: 'NOK -Norwegian Krone' },
  //   { value: 'NPR', label: 'NPR -Nepalese Rupee' },
  //   { value: 'NZD', label: 'NZD -New Zealand Dollar' },
  //   { value: 'OMR', label: 'OMR -Omani Rial' },
  //   { value: 'PAB', label: 'PAB -Panamanian Balboa' },
  //   { value: 'PEN', label: 'PEN -Peruvian Sol' },
  //   { value: 'PGK', label: 'PGK -Papua New Guinean Kina' },
  //   { value: 'PHP', label: 'PHP -Philippine Peso' },
  //   { value: 'PKR', label: 'PKR -Pakistani Rupee' },
  //   { value: 'PLN', label: 'PLN -Polish Zloty' },
  //   { value: 'PYG', label: 'PYG -Paraguayan Guarani' },
  //   { value: 'QAR', label: 'QAR -Qatari Riyal' },
  //   { value: 'RON', label: 'RON -Romanian Leu' },
  //   { value: 'RSD', label: 'RSD -Serbian Dinar' },
  //   { value: 'RUB', label: 'RUB -Russian Ruble' },
  //   { value: 'RWF', label: 'RWF -Rwandan Franc' },
  //   { value: 'SAR', label: 'SAR -Saudi Arabian Riyal' },
  //   { value: 'SBD', label: 'SBD -Solomon Islander Dollar' },
  //   { value: 'SCR', label: 'SCR -Seychellois Rupee' },
  //   { value: 'SDG', label: 'SDG -Sudanese Pound' },
  //   { value: 'SEK', label: 'SEK -Swedish Krona' },
  //   { value: 'SGD', label: 'SGD -Singapore Dollar' },
  //   { value: 'SHP', label: 'SHP -Saint Helenian Pound' },
  //   { value: 'SLE', label: 'SLE -Sierra Leonean Leone' },
  //   { value: 'SLL', label: 'SLL -Sierra Leonean Leone' },
  //   { value: 'SOS', label: 'SOS -Somali Shilling' },
  //   { value: 'SPL', label: 'SPL -Seborgan Luigino' },
  //   { value: 'SRD', label: 'SRD -Surinamese Dollar' },
  //   { value: 'STN', label: 'STN -Sao Tomean Dobra' },
  //   { value: 'SVC', label: 'SVC -Salvadoran Colon' },
  //   { value: 'SYP', label: 'SYP -Syrian Pound' },
  //   { value: 'SZL', label: 'SZL -Swazi Lilangeni' },
  //   { value: 'THB', label: 'THB -Thai Baht' },
  //   { value: 'TJS', label: 'TJS -Tajikistani Somoni' },
  //   { value: 'TMT', label: 'TMT -Turkmenistani Manat' },
  //   { value: 'TND', label: 'TND -Tunisian Dinar' },
  //   { value: 'TOP', label: 'TOP -Tongan Paanga' },
  //   { value: 'TRY', label: 'TRY -Turkish Lira' },
  //   { value: 'TTD', label: 'TTD -Trinidadian Dollar' },
  //   { value: 'TVD', label: 'TVD -Tuvaluan Dollar' },
  //   { value: 'TWD', label: 'TWD -Taiwan New Dollar' },
  //   { value: 'TZS', label: 'TZS -Tanzanian Shilling' },
  //   { value: 'UAH', label: 'UAH -Ukrainian Hryvnia' },
  //   { value: 'UGX', label: 'UGX -Ugandan Shilling' },
  //   { value: 'UNI', label: 'UNI -Uniswap' },
  //   { value: 'USD', label: 'USD -US Dollar' },
  //   { value: 'UYU', label: 'UYU -Uruguayan Peso' },
  //   { value: 'UZS', label: 'UZS -Uzbekistani Som' },
  //   { value: 'VEF', label: 'VEF -Venezuelan Bolívar' },
  //   { value: 'VES', label: 'VES -Venezuelan Bolívar' },
  //   { value: 'VND', label: 'VND -Vietnamese Dong' },
  //   { value: 'VUV', label: 'VUV -Ni-Vanuatu Vatu' },
  //   { value: 'WST', label: 'WST -Samoan Tala' },
  //   { value: 'XAF', label: 'XAF -Central African CFA Franc BEAC' },
  //   { value: 'XAG', label: 'XAG -Silver Ounce' },
  //   { value: 'XAU', label: 'XAU -Gold Ounce' },
  //   { value: 'XCD', label: 'XCD -East Caribbean Dollar' },
  //   { value: 'XDR', label: 'XDR -IMF Special Drawing Rights' },
  //   { value: 'XLM', label: 'XLM -Stellar Lumen' },
  //   { value: 'XOF', label: 'XOF -CFA Franc' },
  //   { value: 'XPD', label: 'XPD -Palladium Ounce' },
  //   { value: 'XPF', label: 'XPF -CFP Franc' },
  //   { value: 'XPT', label: 'XPT -Platinum Ounce' },
  //   { value: 'XRP', label: 'XRP -Ripple' },
  //   { value: 'YER', label: 'YER -Yemeni Rial' },
  //   { value: 'ZAR', label: 'ZAR -South African Rand' },
  //   { value: 'ZMK', label: 'ZMK -Zambian Kwacha' },
  //   { value: 'ZMW', label: 'ZMW -Zambian Kwacha' },
  //   { value: 'ZWD', label: 'ZWD -Zimbabwean Dollar' },
  //   { value: 'ZWL', label: 'ZWL -Zimbabwean Dollar' }
  // ]

  const Scholarship = [
    { value: "chocolate", label: "Self Arranged" },
    { value: "strawberry", label: "Qardan Hasana by jamaat" },
    { value: "vanilla", label: "Qardan Hasana by other source" },
    { value: "vanilla", label: "Scholarship" },
  ];

  return (
    <div className="form-container">
      <div className="container mt-3 row form-cotent">
        <div className="form-header">
          <Row>
            <Col sm={12}>
              <div className="past-entries">
                <h3>
                  Student's Education Details - Survey Form for current
                  education
                </h3>
                <div className="text-note">
                  This form has been prepared for Ummal Kiraam/Masool al-Mawaze
                  and Umoor Talimiyyah Committees for the survey of student's{" "}
                  <strong>current educational details</strong> in their
                  respective mawaze. You may add students details individually
                  and also in bulk. If the students are studying in the same
                  institute and the same course, then only use the BULK FORM, if
                  not, continue to fill the form individually.
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="form-input-container">
          <Row>
            <Col sm={12} lg={6}>
              <div className="past-entries">
                <h3>Verify ITS IDs</h3>
                <div className="text-note">
                  Enter or paste ITS IDs of students whose Education data needs
                  to be updated. The students whose new entry for{" "}
                  <strong>current education</strong> data already exists will be
                  shown below in the table. Students with no data, their ITS IDs
                  will be added to the Data Entry Form for entry.
                  <br />
                  <br />
                  <strong>
                    <span style={{ color: "red" }}>Note</span>:
                  </strong>{" "}
                  Course Start Date and End Date are required and should{" "}
                  <span style={{ color: "red" }}> NOT </span>
                  be the same. Otherwise data will be rejected
                </div>
                <div>
                  <label>Check ITS ID</label>
                  <textarea
                    className="form-control"
                    id="its_id_list"
                    required=""
                    name="its_id_list"
                    rows="8"
                    value={itsValue}
                    onChange={(e) => setItsValue(e.target.value)}
                  ></textarea>
                  {!remaingIts ? (
                    <button
                      type="submit"
                      id="checkItsIdBtn"
                      className="btn btn-primary mt-3"
                      onClick={handleSubmitIts}
                      disabled={loading}
                    >
                      {loading ? "Checking.." : "Check"}
                    </button>
                  ) : (
                    <p style={{ padding: "1em" }}>
                      Use the form in Sidebar to process the missing records.
                    </p>
                  )}
                </div>

                <div className="mb-3 mt-3 itsDataListDiv d-block">
                  <h2>
                    {" "}
                    {itsData?.currently_studing.length > 0
                      ? "Existing entries of these students"
                      : ""}{" "}
                  </h2>
                  <EducationDetail currentData={itsData?.currently_studing} />
                </div>
              </div>
            </Col>
            {remaingIts ? (
              <Col sm={12} lg={6}>
                <div className="entry-form">
                  <h3>Data Entry Form</h3>
                  <div style={{ marginBottom: "20px" }}>
                    <label>ITS ID</label>
                    <div style={{ color: "#999" }}>
                      {remaingIts
                        ? remaingIts
                        : 'Please enter ITS IDs of student in the "Check ITS ID" Text box '}
                    </div>
                    <input
                      type="hidden"
                      required=""
                      name="its_id"
                      value={remaingIts}
                      onChange={(e) => setRemaingIts(e.target.value)}
                    ></input>
                  </div>

                  <Row>
                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">Marhala</label>
                      <span style={{ color: "red" }}>*</span>
                      <div style={{ width: "100%" }}>
                        <Select
                          options={newMurhala}
                          isLoading={newMurhala === undefined ? true : false}
                          onChange={(selectedOptions) =>
                            handleChange(selectedOptions, "marhala")
                          }
                        />
                        <span style={{ color: "#ff0000" }}>
                          {errorsMessage[1]?.marhala}
                        </span>
                      </div>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="course">Course</label>
                      <span style={{ color: "red" }}>*</span>
                      <Select
                        options={newGetCourse}
                        defaultValue={[]}
                        isLoading={
                          newGetCourse === undefined &&
                          Object.keys(selectedMarhala).length !== 0
                            ? true
                            : false
                        }
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "course")
                        }
                      />
                      <span style={{ color: "#ff0000" }}>
                        {errorsMessage[2]?.course}
                      </span>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">
                        Institute Country
                      </label>
                      <span style={{ color: "red" }}>*</span>
                      <Modals
                        type={"country"}
                        handleChangeModal={handleChangeModal}
                        handleSubmitModal={handleSubmitModal}
                        modalValue={modalValue}
                      />
                      <Select
                        options={newGetCountry}
                        defaultValue={[]}
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "country")
                        }
                      />
                      <span style={{ color: "#ff0000" }}>
                        {errorsMessage[3]?.country}
                      </span>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">Institute City</label>
                      <span style={{ color: "red" }}>*</span>
                      <Modals
                        type={"city"}
                        handleChangeModal={handleChangeModal}
                        modalValue={modalValue}
                        handleSubmitModal={handleSubmitModal}
                      />
                      <Select
                        options={newGetCity}
                        defaultValue={[]}
                        isLoading={newGetCity === undefined ? true : false}
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "city")
                        }
                      />
                      <span style={{ color: "#ff0000" }}>
                        {errorsMessage[4]?.city}
                      </span>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="course">Institute</label>
                      <span style={{ color: "red" }}>*</span>
                      <Modals
                        type={"institute"}
                        handleChangeModal={handleChangeModal}
                        modalValue={modalValue}
                        handleSubmitModal={handleSubmitModal}
                      />
                      <Select
                        options={newGetInstitute}
                        defaultValue={[]}
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "institute")
                        }
                      />
                      <span style={{ color: "#ff0000" }}>
                        {errorsMessage[5]?.institute}
                      </span>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">FMB</label>

                      <Select
                        options={newGetFmb}
                        defaultValue={[]}
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "fmb")
                        }
                      />
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">Accommodation</label>
                      <span style={{ color: "red" }}>*</span>
                      <Select
                        options={newGetAccommodation}
                        defaultValue={[]}
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "accommodation")
                        }
                      />
                      <span style={{ color: "#ff0000" }}>
                        {errorsMessage[6]?.accommodation}
                      </span>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="course">Fees (Annual Cost)</label>
                      <input
                        type="number"
                        className="form-control"
                        value={amount}
                        onChange={(e) =>
                          handleFormData("annual_fees", e.target.value)
                        }
                      />
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">
                        Course Start Date{" "}
                      </label>
                      <span style={{ color: "red" }}>*</span>
                      <div>
                        <DatePicker
                          className="inputClass"
                          onChange={(date) =>
                            handleChangeData(date, "course_start_date")
                          }
                          value={entryFormData.course_start_date}
                        />
                      </div>
                    </Col>
                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">
                        Course End Date
                      </label>
                      <span style={{ color: "red" }}>*</span>
                      <div>
                        <DatePicker
                          className="inputClass"
                          onChange={(date) =>
                            handleChangeData(date, "course_end_date")
                          }
                          value={entryFormData.course_end_date}
                        />
                      </div>
                      <span style={{ color: "#ff0000" }}>
                        {errorsMessage?.course_end_date}
                      </span>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">Currency</label>
                      <Select
                        options={newGetCurrency}
                        defaultValue={[]}
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "currency")
                        }
                      />
                    </Col>

                    <Col sm={6} className="mb-3">
                      <label htmlFor="marhala-selectized">Scholarship</label>
                      <Select
                        options={Scholarship}
                        defaultValue={[]}
                        onChange={(selectedOptions) =>
                          handleChange(selectedOptions, "scholarship")
                        }
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      {subMessage && (
                        <div
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            marginBottom: "10px",
                            color:
                              subError === "success" ? "#155815" : "#671313",
                            backgroundColor:
                              subError === "success" ? "#b8f3b8" : "#ffb3b3",
                          }}
                        >
                          {subMessage}
                        </div>
                      )}
                      <button
                        type="submit"
                        id="checkItsIdBtn"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
