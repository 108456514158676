import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import SearchPage from "./SearchPage";
import useFetch from "../../useFetch";
import { Buffer } from "buffer";
import { getCookie, getTokenFromCookie } from "../../Utils/cookieUtils";

export default function AppRoutes() {
  const [allJamaatIds, setAllJamaatIds] = useFetch();
  const [apiData, setApiData] = useFetch();
  const [headerData, setHeaderData] = useFetch();
  const [currentJamaatId, setCurrentJamaatId] = useState();
  const [EduStatus, setEduStatus] = useState(
    "Araz done for currently studying"
  );
  const [downloadRecord, setDownloadRecord] = useState(
    `?cmd=razastatus&jamaat=${currentJamaatId}&raza_status=Araz%20done`
  );
  const [myTask, setMyTask] = useFetch();
  const [ageLimit, setAgeLimit] = useState({ lowerAge: 3, higherAge: 27 });
  const [currentData, setCurrentData] = useState([]);
  const [currentFilterData, setCurrentFilterData] = useState([]);
  const defaultSort = { field: "", direction: "" };
  const [sort, setSort] = useState(defaultSort);
  const [token, setToken] = useState(false);
  const [currentType, setCurrentType] = useState({
    label: "Dunyawi",
    value: "1",
  });
  const [prevType, setPrevType] = useState(1);
  const [noDataFound, setNoDataFound] = useState(false);

  const handleRequest = (verb, lable, downlaod) => {
    setApiData("");
    setSort(defaultSort);
    setApiData(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/allFilters${
        verb ? verb : ""
      }`
    );
    setEduStatus(lable);
    setDownloadRecord(downlaod);
    setCurrentData(apiData);
  };

  useEffect(() => {
    const tokendata = getTokenFromCookie();
    setToken(tokendata);
    if (headerData && headerData?.jamaat_id) {
      const inititalData = allJamaatIds?.filter(
        (item) => item.is_primary === 1
      );
      setMyTask(
        `profile/aamilsaheb/deeni/taskList/${inititalData[0]?.jamaat_id}`
      );
      setCurrentJamaatId(inititalData[0]?.jamaat_id);
    }
  }, [allJamaatIds]);

  useEffect(() => {
    setCurrentData(apiData);
  }, [apiData]);

  useEffect(() => {
    handleRequest();
  }, []);

  const handleSearch = (searchData) => {
    const { its_id, name, course, institute, year, stream, jamaat, byStatus } =
      searchData;
    const itsArray = its_id ? its_id?.split(",") : [];
    let courseFiled = {};
    if (course) {
      courseFiled = {
        current_edu_course: course,
        future_edu_course: course,
        last_edu_course: course,
      };
    }

    let instituteField = {};
    if (institute) {
      instituteField = {
        current_edu_institute: institute,
        future_edu_institute: institute,
        last_edu_institute: institute,
      };
    }

    let streamFeild = {};
    if (stream) {
      streamFeild = {
        current_edu_course_group: stream,
        future_edu_course_group: stream,
        last_edu_course_group: stream,
      };
    }

    // const commonFields = Object.entries(commonField).filter((data) => data[1]);
    const isMatch = (searchItem, currentItem) => {
      return (
        searchItem &&
        currentItem?.toLowerCase().includes(searchItem.toLowerCase().toString())
      );
    };
    const newFilterd =
      its_id ||
      name ||
      course ||
      institute ||
      year ||
      stream ||
      jamaat ||
      byStatus
        ? currentData?.filter((item) => {
            // let isMatchd =
            //   commonFields && commonFields.length > 0
            //     ? commonFields?.every((i) => isMatch(i[1], item[i[0]]))
            //     : false;
            let itsMatch = itsArray
              ?.map((data1) => data1?.toLowerCase().trim())
              ?.includes(item.its_id.toString().trim());

            let courseMatch = Object.entries(courseFiled)?.some((data2) =>
              [
                item?.future_edu_course?.toLowerCase(),
                item?.current_edu_course?.toLowerCase(),
                item?.last_edu_course?.toLowerCase(),
              ]?.includes(data2[1]?.toLowerCase())
            );

            let instituteMatch = Object.entries(instituteField)?.some((data3) =>
              [
                item?.future_edu_institute?.toLowerCase(),
                item?.current_edu_institute?.toLowerCase(),
                item?.last_edu_institute?.toLowerCase(),
              ]?.includes(data3[1]?.toLowerCase())
            );

            let streamMatch = Object.entries(streamFeild)?.some((data4) =>
              [
                item?.future_edu_course_group?.toLowerCase(),
                item?.current_edu_course_group?.toLowerCase(),
                item?.last_edu_course_group?.toLowerCase(),
              ]?.includes(data4[1]?.toLowerCase())
            );

            let isNameMatch = isMatch(name, item?.name);

            let isJamaatMatch = isMatch(jamaat, item.jamaat);

            let isStatusMatch = true;
            if (byStatus && byStatus === "Is Dropout") {
              isStatusMatch = isMatch("1", item.is_dropout);
            }
            if (byStatus && byStatus === "Not Dropout") {
              isStatusMatch = isMatch("0", item.is_dropout);
            }
            if (byStatus && byStatus === "Is Migrated Out") {
              isStatusMatch = isMatch("1", item.is_migrated);
            }
            if (byStatus && byStatus === "Not Migrated Out") {
              isStatusMatch = isMatch("0", item.is_migrated);
            }
            let startYearMatch = false;
            let endYearMatch = false;

            let futureStartYear = Number(
              item?.future_edu_course_start_date?.split("-")[0]
            )
              ? Number(item?.future_edu_course_start_date?.split("-")[0])
              : 0;
            let currentStartYear = Number(
              item?.current_edu_course_start_date?.split("-")[0]
            )
              ? Number(item?.current_edu_course_start_date?.split("-")[0])
              : 0;
            let lastStartyear = Number(
              item?.last_edu_course_start_date?.split("-")[0]
            )
              ? Number(item?.last_edu_course_start_date?.split("-")[0])
              : 0;

            let futureEndYear = Number(
              item?.future_edu_course_end_date?.split("-")[0]
            )
              ? Number(item?.future_edu_course_end_date?.split("-")[0])
              : 0;
            let currentEndYear = Number(
              item?.current_edu_course_end_date?.split("-")[0]
            )
              ? Number(item?.current_edu_course_end_date?.split("-")[0])
              : 0;
            let lastEndyear = Number(
              item?.last_edu_course_end_date?.split("-")[0]
            )
              ? Number(item?.last_edu_course_end_date?.split("-")[0])
              : 0;

            if (year) {
              startYearMatch =
                (futureStartYear && futureStartYear <= year) ||
                (currentStartYear && currentStartYear <= year) ||
                (lastStartyear && lastStartyear <= year);
              endYearMatch =
                (futureEndYear && futureEndYear >= year) ||
                (currentEndYear && currentEndYear >= year) ||
                (lastEndyear && lastEndyear >= year);
            }

            let finalCondition = true;
            if (its_id) {
              finalCondition = itsMatch;
            }
            if (course && finalCondition) {
              finalCondition = courseMatch;
            }
            if (institute && finalCondition) {
              finalCondition = instituteMatch;
            }
            if (name && finalCondition) {
              finalCondition = isNameMatch;
            }
            if (year && finalCondition) {
              finalCondition = startYearMatch && endYearMatch;
            }
            if (stream && finalCondition) {
              finalCondition = streamMatch;
            }
            if (jamaat && finalCondition) {
              finalCondition = isJamaatMatch;
            }
            if (byStatus && finalCondition) {
              finalCondition = isStatusMatch;
            }
            if (finalCondition) {
              return item;
            }
          })
        : currentData;
    const haveSearch = Object.values(searchData).filter((item) => item !== "");
    haveSearch?.length > 0 && newFilterd?.length === 0
      ? setNoDataFound(true)
      : setNoDataFound(false);
    setCurrentFilterData(newFilterd);
  };
  const handleReset = () => {
    setCurrentFilterData([]);
  };

  const handleFilterType = (data) => {
    if (prevType !== data.value) {
      setCurrentType(data);
      setPrevType(data.value);
      data.value == 1
        ? setApiData(
            `${process.env.REACT_APP_PROFILE_API}aamilsaheb/allFilters`
          )
        : setApiData(
            `${process.env.REACT_APP_PROFILE_API}aamilsaheb/deeni/allFilters`
          );
    }
  };
  return (
    <>
      <SearchPage
        currentData={currentData}
        currentFilterData={currentFilterData}
        handleSearch={handleSearch}
        handleFilterType={handleFilterType}
        handleReset={handleReset}
        currentType={currentType}
        noDataFound={noDataFound}
      />
      <ToastContainer />
    </>
  );
}
