import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import EducationDetail from "../EducationDetail";
import HashLoader from "react-spinners/HashLoader";
import Select from "react-select";

export default function LandingPage(props) {
  const {
    currentData,
    currentFilterData,
    handleSearch,
    handleReset,
    handleFilterType,
    currentType,
    noDataFound,
  } = props;
  const [color, setColor] = useState("#00336D");
  const [courseOption, setcourseOption] = useState();
  const [instituteOption, setInstituteOption] = useState();
  const [jamaatOption, setJamaatOption] = useState();
  const [streamOptions, setStreamOptions] = useState();

  const initialState = {
    its_id: "",
    name: "",
    course: "",
    stream: "",
    institute: "",
    jammat: "",
    year: "",
    byStatus: "",
  };
  const [formState, setFormState] = useState(initialState);
  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch({
      ...formState,
      year: formState?.year?.value ? formState?.year?.value : "",
    });
  };

  const handleResetReq = () => {
    handleReset();
  };

  const sortAndFilter = (current, future, last) => {
    let course = [
      ...new Set(currentData.map((item) => item[current])),
      ...new Set(currentData.map((item) => item[future])),
      ...new Set(currentData.map((item) => item[last])),
    ]
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .filter((el) => el != "" && el != null);
    const uniqueData = [];
    course.map((item) => {
      if (uniqueData.indexOf(item) === -1) {
        uniqueData.push(item);
      }
    });
    return uniqueData;
  };

  const sortAndFilterJamaat = () => {
    let jamaat = [...new Set(currentData.map((item) => item["jamaat"]))]
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .filter((el) => el != "" && el != null);

    const uniqueData = [];
    jamaat.map((item) => {
      if (uniqueData.indexOf(item) === -1) {
        uniqueData.push(item);
      }
    });
    return uniqueData;
  };

  useEffect(() => {
    if (currentData && currentData.length > 0) {
      const uniqueCourses = sortAndFilter(
        "current_edu_course",
        "future_edu_course",
        "last_edu_course"
      );

      const uniqueInstitutes = sortAndFilter(
        "current_edu_institute",
        "future_edu_institute",
        "last_edu_institute"
      );

      const uniqueStreams = sortAndFilter(
        "current_edu_course_group",
        "future_edu_course_group",
        "last_edu_course_group"
      );

      const uniqueJamaat = sortAndFilterJamaat();

      setcourseOption(
        uniqueCourses.map((item) => {
          return { label: item, value: item };
        })
      );

      setInstituteOption(
        uniqueInstitutes.map((item) => {
          return { label: item, value: item };
        })
      );

      setJamaatOption(
        uniqueJamaat.map((item) => {
          return { label: item, value: item };
        })
      );

      setStreamOptions(
        uniqueStreams.map((item) => {
          return { label: item, value: item };
        })
      );
    }
  }, [currentData]);
  //year dropdown option
  const d = new Date();
  let year = d.getFullYear();
  let preYear = year - 10;
  let futYear = year + 4;
  let newYear = "";
  let yearOptions = preYear;
  for (let i = preYear; i <= futYear; i++) {
    newYear = String(i + 1);
    yearOptions = yearOptions + "," + newYear;
  }
  const yearOptionsArray = yearOptions.split(",").map((item) => {
    return { label: item, value: item };
  });

  const handleYear = (e) => {
    setFormState({ ...formState, year: e });
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row
          style={{
            backgroundColor: "#E5E5E5",
            padding: "10px 20px",
          }}
        >
          <Col sm={12} lg={2} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}>ITS :</label>
            <input
              type="text"
              className={"form-control input-get"}
              name="its_id"
              value={formState.its_id}
              onChange={(e) => handleChange(e)}
              placeholder="Name"
              style={{ padding: "18px" }}
            />
          </Col>
          <Col sm={12} lg={4} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}>Name :</label>
            <input
              type="text"
              className={"form-control input-get"}
              name="name"
              value={formState.name}
              onChange={(e) => handleChange(e)}
              placeholder="Name"
              style={{ padding: "18px" }}
            />
          </Col>
          <Col sm={12} lg={4} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}>Course :</label>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "8px",
                }),
              }}
              placeholder="Select Course"
              options={courseOption}
              value={
                formState.course
                  ? { label: formState.course, value: formState.course }
                  : ""
              }
              // isLoading={newMurhala === undefined ? true : false}
              onChange={(selectedOptions) => {
                setFormState({ ...formState, course: selectedOptions.value });
              }}
            />
          </Col>
          <Col sm={12} lg={2} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}>Stream :</label>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "8px",
                }),
              }}
              placeholder="Select Stream"
              options={streamOptions}
              value={
                formState.stream
                  ? { label: formState.stream, value: formState.stream }
                  : ""
              }
              // isLoading={newMurhala === undefined ? true : false}
              onChange={(selectedOptions) => {
                setFormState({ ...formState, stream: selectedOptions.value });
              }}
            />
          </Col>
          <Col sm={12} lg={4} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}>Institute :</label>
            <Select
              options={instituteOption}
              placeholder={"Select Institute"}
              value={
                formState.institute
                  ? { label: formState.institute, value: formState.institute }
                  : ""
              }
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "8px",
                }),
              }}
              // isLoading={newMurhala === undefined ? true : false}
              onChange={(selectedOptions) =>
                setFormState({ ...formState, institute: selectedOptions.value })
              }
            />
          </Col>
          <Col sm={12} lg={2} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}>Year :</label>
            <Select
              options={yearOptionsArray}
              value={formState.year.year}
              name="year"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "8px",
                }),
              }}
              onChange={(e) => handleYear(e)}
              placeholder="Year"
            />
          </Col>
          <Col sm={12} lg={2} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}> By Status:</label>
            <Select
              onChange={(selectedOptions) =>
                setFormState({ ...formState, byStatus: selectedOptions.value })
              }
              placeholder="By Status"
              value={
                formState.byStatus
                  ? { label: formState.byStatus, value: formState.byStatus }
                  : ""
              }
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "8px",
                }),
              }}
              options={[
                { label: "Is Dropout", value: "Is Dropout" },
                { label: "Is Migrated Out", value: "Is Migrated Out" },
                { label: "Not Dropout", value: "Not Dropout" },
                { label: "Not Migrated Out", value: "Not Migrated Out" },
              ]}
            />
          </Col>
          <Col sm={12} lg={2} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}>Jamaat :</label>
            <Select
              options={jamaatOption}
              placeholder={"Select Jamaat"}
              value={
                formState.jamaat
                  ? { label: formState.jamaat, value: formState.jamaat }
                  : ""
              }
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "8px",
                }),
              }}
              // isLoading={newMurhala === undefined ? true : false}
              onChange={(selectedOptions) =>
                setFormState({ ...formState, jamaat: selectedOptions.value })
              }
            />
          </Col>
          <Col sm={12} lg={2} md={4} className="p-1">
            <label style={{ padding: "2px 4px" }}> Filter Type:</label>
            <Select
              onChange={(selectedOptions) => handleFilterType(selectedOptions)}
              placeholder="Filter Type"
              value={currentType ? currentType : ""}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "8px",
                }),
              }}
              options={[
                { label: "Dunyawi", value: "1" },
                { label: "Deeni", value: "2" },
              ]}
            />
          </Col>
          <Col sm={12} lg={2} md={4} className="p-1">
            <button
              className="btn btn-primary"
              type="submit"
              style={{ padding: "12px 24px", marginTop: "30px" }}
            >
              Search
            </button>
            <input
              className="btn btn-secondary me-1"
              onClick={() => {
                handleResetReq();
                setFormState(initialState);
              }}
              style={{
                padding: "12px 24px",
                marginTop: "30px",
                marginLeft: "10px",
              }}
              type="button"
              value="Reset"
            />
          </Col>
        </Row>
      </form>
      {currentFilterData && currentFilterData.length !== 0 ? (
        <Row
          style={{
            padding: "10px 20px",
          }}
        >
          <EducationDetail currentData={currentFilterData} />
        </Row>
      ) : noDataFound ? (
        <div className="loader-content">No Data Found</div>
      ) : (
        currentFilterData &&
        currentFilterData.length === 0 && (
          <div className="loader-content">
            Please enter search criteria ....
            {/* <HashLoader color={color} size={78} /> */}
          </div>
        )
      )}
    </>
  );
}
