import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import useFetch from "../../useFetch";
import { getTokenFromCookie } from "../../Utils/cookieUtils";

import "./MigratedInStudent.css";
import { toast } from "react-toastify";

const getToken = getTokenFromCookie();

const MigratedInStudent = ({ dropdownList }) => {
  const [allJamaatIds, setAllJamaatIds] = useFetch();
  const [apiData, setApiData] = useFetch();
  const [token, setToken] = useState(false);
  const [ageLimit, setAgeLimit] = useState({ lowerAge: 3, higherAge: 27 });

  const [currentJamaatId, setCurrentJamaatId] = useState();

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // setUserFullName(process.env.REACT_APP_PROFILE_API);
    setAllJamaatIds(
      `${process.env.REACT_APP_PROFILE_API}aamilsaheb/jamaatIDByitsID`
    );
  }, [token]);

  useEffect(() => {
    const tokens = getTokenFromCookie();
    setToken(tokens);
    if (allJamaatIds && allJamaatIds.length > 0) {
      const inititalData = allJamaatIds?.filter(
        (item) => item.is_primary === 1
      );
      setCurrentJamaatId(inititalData[0]?.jamaat_id);
    }
  }, [allJamaatIds]);

  const fetchMigratedInStudentList = () => {
    const verb = `migratedInUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}`;
    setApiData(`${process.env.REACT_APP_PROFILE_API}aamilsaheb/${verb}`);
  };

  useEffect(() => {
    fetchMigratedInStudentList();
  }, [currentJamaatId]);

  const tagStudent = async (guardianITS, studentITS) => {
    await fetch(
      `${process.env.REACT_APP_BASE_API}migrating_students/aamilsaheb/tagStudent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${getToken}`,
        },
        body: JSON.stringify({
          member_its: guardianITS,
          student_its: studentITS,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) =>
        data.success ? toast.success(data.message) : toast.error(data.message)
      );
    fetchMigratedInStudentList();
  };

  const handleRemove = async (studentITS) => {
    if (deleteConfirmation) {
      await fetch(
        `${process.env.REACT_APP_BASE_API}migrating_students/aamilsaheb/removeTag/${studentITS}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${getToken}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => toast.success("Guardian removed"));
      fetchMigratedInStudentList();
    }
  };

  return (
    <Col xs={12} lg={6} className="mt-3">
      <div className="explaination-box d-none d-sm-block">
        <h4 className="text-info">Migrated in students list</h4>
        <hr className="underline" />
        {!apiData ? (
          <h4>Please wait....</h4>
        ) : apiData?.length > 0 ? (
          apiData.map((student, i) => (
            <div
              key={student + i}
              style={{
                boxShadow: "0 0.2rem 0.32rem #dfdfdf",
                borderRadius: "0.4rem",
                padding: "0.4rem 0.6rem",
                margin: "1rem 0",
              }}
            >
              <div
                key={student.its_id + i}
                className="d-flex justify-content-between"
              >
                <div>
                  <p className="m-1" style={{ fontSize: "1rem" }}>
                    <span style={{ fontWeight: 600 }}>ITS ID: </span>
                    {student.its_id}
                  </p>
                  <p className="m-1" style={{ fontSize: "1rem" }}>
                    <span style={{ fontWeight: 600 }}>Name: </span>
                    {student.name}
                  </p>
                  <p className="m-1" style={{ fontSize: "1rem" }}>
                    <span style={{ fontWeight: 600 }}>Jamaat: </span>
                    {student.jamaat}
                  </p>
                  <p className="m-1" style={{ fontSize: "1rem" }}>
                    <span style={{ fontWeight: 600 }}>Age: </span>
                    {student.age}
                  </p>
                  <p className="m-1" style={{ fontSize: "1rem" }}>
                    <span style={{ fontWeight: 600 }}>Email: </span>
                    {student.email}
                  </p>
                  <p className="m-1" style={{ fontSize: "1rem" }}>
                    <span style={{ fontWeight: 600 }}>Mobile: </span>
                    {student.mobile}
                  </p>
                </div>
                <div
                  style={{ width: "25%" }}
                  className="d-flex flex-column justify-content-between align-items-center"
                >
                  <img
                    src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${student.its_id}.png`}
                    alt="user-avatar"
                    style={{
                      width: "50%",
                      borderRadius: "10%",
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <p className="m-0" style={{ fontSize: "1rem" }}>
                      <span style={{ fontWeight: 600 }}>Guardian:</span>
                    </p>
                    <select
                      style={{
                        padding: "0.2rem 0.4rem",
                        borderRadius: "0.4rem",
                        maxWidth: "100%",
                      }}
                      onChange={(e) =>
                        e.target.value === "remove"
                          ? handleRemove(student.its_id)
                          : tagStudent(
                              Number(e.target.value),
                              Number(student.its_id)
                            )
                      }
                      defaultValue={student.member_its || ""}
                    >
                      <option value="">Select</option>
                      {dropdownList &&
                        dropdownList.map((guardian, i) => (
                          <option
                            value={guardian.its_id}
                            key={guardian.its_id + i}
                          >{`${guardian.name}  ~  ${guardian.taggingCount} students`}</option>
                        ))}
                      <option value="remove" className="removeGuardian">
                        Remove guardian
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Footer */}
              {student.member_its && student.member_name && (
                <>
                  <hr />
                  <div className="d-flex justify-content-between mx-1">
                    <p className="m-0" style={{ fontSize: "1rem" }}>
                      <span style={{ fontWeight: 600 }}>Guardian:</span>
                      <span style={{ fontWeight: 400 }}>
                        {" "}
                        {student.member_name}
                      </span>
                    </p>
                    <p className="m-0" style={{ fontSize: "1rem" }}>
                      <span style={{ fontWeight: 400 }}>
                        {student.member_its}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          ))
        ) : (
          <h4>No migrated in students available</h4>
        )}
      </div>
      {/* {showPopup && (
        <div className="deletePopup">
          <div>
            <h5>Are you sure you want to remove the guardian?</h5>
            <h6>This action cannot be undone</h6>
          </div>
          <div className="popupFooter">
            <Button>Cancel</Button>
            <Button>Confirm</Button>
          </div>
        </div>
      )} */}
    </Col>
  );
};

export default MigratedInStudent;
